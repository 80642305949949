import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';

import AppRadioGroup from '../../../components/AppRadioGroup';
import { PERMISSION_TYPES } from '../../../constants';
import { IAdvancedForm } from '../constants/validation-schemas';

const AdvancedFormView = () => {
  const form = useFormContext<IAdvancedForm>();

  return (
    <>
      <Typography variant="h5" fontWeight={600} mb={3}>
        Advanced Options
      </Typography>
      <Grid container>
        <Grid item xs={10}>
          <Controller
            name="permissionLevel"
            control={form.control}
            render={({ field: { onChange, onBlur, value } }) => (
              <AppRadioGroup
                label="Permission Level"
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                row>
                <AppRadioGroup.Radio
                  value={PERMISSION_TYPES.MANAGER}
                  label="Manager Only"
                />
                <AppRadioGroup.Radio
                  value={PERMISSION_TYPES.ANY}
                  label="Any User"
                />
              </AppRadioGroup>
            )}
          />
        </Grid>
        {/*TODO: Hidden temporally <Grid item xs={12}>
          <Controller
            name="discountReasons"
            control={form.control}
            render={({
              field: { onChange, onBlur, value = [] },
            }) => (
              <DiscountReasonsSelector
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
        </Grid> */}
        <Grid item xs={10}>
          <Controller
            name="combineDiscounts"
            control={form.control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                    }}
                    color="primary"
                    disabled
                  />
                }
                label="Combine Discount"
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AdvancedFormView;
