import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';

import { IAvailabilityForm } from '../constants/validation-schemas';

const AvailabilityFormView = () => {
  const form = useFormContext<IAvailabilityForm>();

  return (
    <>
      <Typography variant="h5" fontWeight={600} mb={3}>
        Availability
      </Typography>
      <Controller
        name="isAvailableAlways"
        control={form.control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                }}
                value={field.value}
                color="primary"
                disabled
              />
            }
            label="Always available"
          />
        )}
      />
    </>
  );
};

export default AvailabilityFormView;
