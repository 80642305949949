import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const AppCheckbox = ({
  label,
  value,
  isChecked,
  onChange,
  ...props
}: {
  label: string;
  value: string;
  isChecked: boolean;
} & CheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...props}
          checked={isChecked}
          value={value}
          onChange={onChange}
        />
      }
      label={label}
    />
  );
};

export default AppCheckbox;
