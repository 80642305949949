import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import AppButton from '../../../components/AppButton';
import { useConfirmationPopup } from '../../../components/AppConfirmationPopup';
import AppDivider from '../../../components/AppDivider';
import { DISCOUNT_APPLY_TYPES } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectDiscounts } from '../../../redux/selectors/discountsSelectors';
import { Colors } from '../../../theme/colors';
import { DISCOUNT_STEP_ID } from '../constants';
import {
  appliedToValidationSchema,
  IAppliedToForm,
} from '../constants/validation-schemas';
import ViewLayout from '../hocs/ViewLayout';
import useDiscountStepValidator from '../hooks/useDiscountStepValidator';
import { resetCreatingData, setCreatingData } from '../redux/discountsSlice';
import AppliedToFormView from '../views/AppliedToFormView';

const AppliedToPage = () => {
  const dispatch = useAppDispatch();
  const { openConfirmationPopup } = useConfirmationPopup();
  const { creatingData } = useAppSelector(selectDiscounts);
  const navigate = useNavigate();
  useDiscountStepValidator(DISCOUNT_STEP_ID.APPLIED_TO);

  const form = useForm<IAppliedToForm>({
    resolver: yupResolver(appliedToValidationSchema),
    defaultValues: {
      applyType: creatingData?.applyType ?? ('' as DISCOUNT_APPLY_TYPES),
      removeDiscount: creatingData?.removeDiscount ?? false,
      excludedCategories: creatingData?.excludedCategories ?? [],
      excludedDishes: creatingData?.excludedDishes ?? [],
      includedDishes: creatingData?.includedDishes ?? [],
      includedCategories: creatingData?.includedCategories ?? [],
    },
  });

  const onSubmit = form.handleSubmit((data) => {
    dispatch(
      setCreatingData({
        ...creatingData,
        applyType: data.applyType,
        removeDiscount: data.removeDiscount,
        excludedCategories: data.excludedCategories,
        excludedDishes: data.excludedDishes,
        includedDishes: data.includedDishes,
        includedCategories: data.includedCategories,
      }),
    );
    navigate('/discounts/create/availability');
  });

  const handlePreviousClick = () => {
    openConfirmationPopup({
      heading: `Discard Discount Changes?`,
      content: 'You will loose all the changes made to this discount.',
      confirmButtonTitle: 'Discard changes',
      cancelButtonTitle: 'Keep editing',

      onConfirm: () => {
        dispatch(resetCreatingData());
        navigate('/discounts/all-discounts');
      },
    });
  };

  return (
    <ViewLayout>
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <AppliedToFormView />
          <AppDivider
            style={{
              borderColor: Colors.greyBorderLogin,
              borderTopStyle: 'solid',
              mt: 3,
              mb: 1,
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 2,
              gap: 2,
            }}>
            <AppButton
              size="large"
              title="Cancel"
              color="secondary"
              variant="contained"
              onClick={handlePreviousClick}
            />
            <AppButton
              size="large"
              title="Next"
              variant="contained"
              type="submit"
            />
          </Box>
        </form>
      </FormProvider>
    </ViewLayout>
  );
};
export default AppliedToPage;
