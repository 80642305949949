import { DISCOUNT_TYPES } from '../../../constants';
import {
  advancedValidationSchema,
  appliedToValidationSchema,
  availabilityValidationSchema,
  discountDetailsValidationSchema,
  IAdvancedForm,
  IAppliedToForm,
  IAvailabilityForm,
  IDiscountDetailsForm,
} from '../constants/validation-schemas';

export const validateCreateDiscountStep = async (
  stepId: string,
  data?: Partial<
    IDiscountDetailsForm | IAppliedToForm | IAvailabilityForm | IAdvancedForm
  >,
) => {
  try {
    if (!data) {
      return false;
    }

    switch (stepId) {
      case 'discount-details':
        return !!(await discountDetailsValidationSchema.validate(data, {
          abortEarly: false,
        }));
      case 'applied-to':
        return !!(await appliedToValidationSchema.validate(data, {
          abortEarly: false,
        }));
      case 'availability':
        return !!(await availabilityValidationSchema.validate(data, {
          abortEarly: false,
        }));
      case 'advanced':
        return !!(await advancedValidationSchema.validate(data, {
          abortEarly: false,
        }));
      default:
        return false;
    }
  } catch {
    return false;
  }
};

export const isFixedDiscountType = (discountType: DISCOUNT_TYPES) => {
  return [DISCOUNT_TYPES.FIXED_PERCENTAGE, DISCOUNT_TYPES.FIXED_VALUE].includes(
    discountType,
  );
};
