import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import AppCheckbox from '../../../components/AppCheckbox';
import AppRadioGroup from '../../../components/AppRadioGroup';
import { DISCOUNT_APPLY_TYPES } from '../../../constants';
import ComboCategoryDishSelector from '../components/ComboCategoryDishSelector';
import CustomRadioButton from '../components/CustomRadioButton';
import { IAppliedToForm } from '../constants/validation-schemas';

type Props = {
  disabled?: boolean;
};

const AppliedToFormView = (props: Props) => {
  const form = useFormContext<IAppliedToForm>();

  const applyTypeWatch = useWatch({
    control: form.control,
    name: 'applyType',
  });
  const removeDiscountWatch = useWatch({
    control: form.control,
    name: 'removeDiscount',
  });
  const excludedCategoriesWatch = useWatch({
    control: form.control,
    name: 'excludedCategories',
  });
  const excludedDishesWatch = useWatch({
    control: form.control,
    name: 'excludedDishes',
  });
  const includedCategoriesWatch = useWatch({
    control: form.control,
    name: 'includedCategories',
  });
  const includedDishesWatch = useWatch({
    control: form.control,
    name: 'includedDishes',
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}>
      <Typography variant="h5" fontWeight={600} mb={2}>
        Discounts will be applied to
      </Typography>
      <Controller
        name="applyType"
        control={form.control}
        render={({ field }) => (
          <AppRadioGroup
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value);
              form.clearErrors('applyType');
            }}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              ml: '12px',
              gap: 1,
            }}
            error={form.formState.errors.applyType?.message}
            touched={true}
            // NOTE: Cannot edit discount apply type if editing data is present
            disabled={!!props.disabled}>
            <CustomRadioButton
              defaultValue={DISCOUNT_APPLY_TYPES.ANY_ITEM}
              value={field.value}
              label={'Any item'}
              disabled={!!props.disabled || form.formState.isSubmitting}
              error={form.formState.errors.applyType?.message}
            />
            <CustomRadioButton
              defaultValue={DISCOUNT_APPLY_TYPES.FULL_ORDER}
              value={field.value}
              label={'Full order'}
              disabled={!!props.disabled || form.formState.isSubmitting}
              error={form.formState.errors.applyType?.message}
            />
            <CustomRadioButton
              defaultValue={DISCOUNT_APPLY_TYPES.SPECIFIC_ITEM}
              value={field.value}
              label={'Specific\nitem/category'}
              disabled={!!props.disabled || form.formState.isSubmitting}
              error={form.formState.errors.applyType?.message}
            />
          </AppRadioGroup>
        )}
      />
      {applyTypeWatch === DISCOUNT_APPLY_TYPES.FULL_ORDER ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}>
          <Controller
            name="removeDiscount"
            control={form.control}
            render={({ field }) => (
              <AppCheckbox
                isChecked={!!field.value}
                value={field.name}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                }}
                color="primary"
                label="Remove discount for the following categories/items."
              />
            )}
          />
          {removeDiscountWatch ? (
            <Box>
              <ComboCategoryDishSelector
                dishes={excludedDishesWatch ?? []}
                categories={excludedCategoriesWatch ?? []}
                onCategoriesChange={(categories) => {
                  form.setValue('excludedCategories', categories);
                  form.trigger('excludedCategories');
                }}
                onDishesChange={(dishes) => {
                  form.setValue('excludedDishes', dishes);
                  form.trigger('excludedDishes');
                }}
                error={
                  form.formState.errors.excludedCategories?.message ??
                  form.formState.errors.excludedDishes?.message
                }
              />
            </Box>
          ) : null}
        </Box>
      ) : null}

      {applyTypeWatch === DISCOUNT_APPLY_TYPES.SPECIFIC_ITEM ? (
        <Box>
          <ComboCategoryDishSelector
            dishes={includedDishesWatch ?? []}
            categories={includedCategoriesWatch ?? []}
            onCategoriesChange={(categories) => {
              form.setValue('includedCategories', categories);
              form.trigger('includedCategories');
            }}
            onDishesChange={(dishes) => {
              form.setValue('includedDishes', dishes);
              form.trigger('includedDishes');
            }}
            error={
              form.formState.errors.includedCategories?.message ??
              form.formState.errors.includedDishes?.message
            }
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default AppliedToFormView;
