import { useCallback, useEffect, useState } from 'react';

import { useAppSelector } from '../../../hooks';
import { selectDiscounts } from '../../../redux/selectors/discountsSelectors';
import { decimalCalculations } from '../../../utils';
import { DISCOUNT_STEP_ID } from '../constants';
import {
  advancedValidationSchema,
  appliedToValidationSchema,
  availabilityValidationSchema,
  discountDetailsValidationSchema,
} from '../constants/validation-schemas';

const useStepCompletedStatus = (stepId: DISCOUNT_STEP_ID) => {
  const [isCompleted, setIsCompleted] = useState<boolean | undefined>();
  const { creatingData } = useAppSelector(selectDiscounts);

  const getCompletedStatus = useCallback(async () => {
    try {
      switch (stepId) {
        case DISCOUNT_STEP_ID.DISCOUNT_DETAILS:
          return !!(await discountDetailsValidationSchema.validate({
            discountName: creatingData?.name,
            discountType: creatingData?.type,
            discountValue: decimalCalculations(creatingData?.value)
              .toDecimal()
              .toString(),
            discountDescription: creatingData?.description,
          }));

        case DISCOUNT_STEP_ID.APPLIED_TO:
          return !!(await appliedToValidationSchema.validate({
            applyType: creatingData?.applyType,
            removeDiscount: creatingData?.removeDiscount,
            excludedCategories: creatingData?.excludedCategories,
            excludedDishes: creatingData?.excludedDishes,
            includedDishes: creatingData?.includedDishes,
            includedCategories: creatingData?.includedCategories,
          }));
        case DISCOUNT_STEP_ID.AVAILABILITY:
          // If isAvailableAlways is undefined, then the form is not yet filled
          if (creatingData?.isAvailableAlways === undefined) return false;

          return !!(await availabilityValidationSchema.validate({
            isAvailableAlways: creatingData?.isAvailableAlways,
          }));

        case DISCOUNT_STEP_ID.ADVANCED:
          return !!(await advancedValidationSchema.validate({
            combineDiscounts: creatingData?.combineDiscounts,
            permissionLevel: creatingData?.permissionLevel,
            discountReasons: creatingData?.discountReasons,
          }));
        default:
          return false;
      }
    } catch {
      return false;
    }
  }, [
    creatingData?.applyType,
    creatingData?.combineDiscounts,
    creatingData?.description,
    creatingData?.discountReasons,
    creatingData?.excludedCategories,
    creatingData?.excludedDishes,
    creatingData?.includedCategories,
    creatingData?.includedDishes,
    creatingData?.isAvailableAlways,
    creatingData?.name,
    creatingData?.permissionLevel,
    creatingData?.removeDiscount,
    creatingData?.type,
    creatingData?.value,
    stepId,
  ]);

  useEffect(() => {
    getCompletedStatus().then(setIsCompleted);
  }, [getCompletedStatus]);

  return isCompleted;
};

export default useStepCompletedStatus;
