import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectCategories } from '../../../redux/selectors/categoriesSelectors';
import { selectDishes } from '../../../redux/selectors/dishesSelectors';
import { fetchAllCategoriesData } from '../../menu/redux/categoriesSlice';
import { fetchAllDishData } from '../../menu/redux/dishesSlice';

export const useCategorizeDishesByCategories = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);
      await dispatch(fetchAllCategoriesData());
      await dispatch(fetchAllDishData());
      setIsLoading(false);
    };

    initializeData();
  }, [dispatch]);

  const { categoriesData } = useAppSelector(selectCategories);
  const { dishesData } = useAppSelector(selectDishes);

  const dishesByCategory = groupBy(
    dishesData.flatMap((dish) =>
      dish.categories.map((category) => ({ ...dish, category: category._id })),
    ),
    'category',
  );

  return {
    data: filter(
      categoriesData.map((category) => ({
        category,
        dishes: dishesByCategory[category._id] || [],
      })),
      (item) => item.dishes.length > 0,
    ),
    isLoading,
  };
};
