import ExpandMore from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import truncate from 'lodash/truncate';
import uniq from 'lodash/uniq';
import { useCallback, useState } from 'react';

import { Colors } from '../../../theme/colors';
import { useCategorizeDishesByCategories } from '../hooks/useCategorizeDishesByCategories';

type Props = {
  dishes: string[];
  categories: string[];
  onCategoriesChange: (categories: string[]) => void;
  onDishesChange: (dishes: string[]) => void;
  error?: string;
};

const ComboCategoryDishSelector = ({
  onCategoriesChange,
  onDishesChange,
  dishes,
  categories,
  error,
}: Props) => {
  const [collapsedCategories, setCollapsedCategories] = useState<
    string[] | null
  >(null);
  const { data: groupedData, isLoading } = useCategorizeDishesByCategories();

  const addCategoryIfAllDishesSelected = useCallback(
    (newDishes: string[]) => {
      const items = groupedData.filter((item) =>
        item.dishes.every((dish) => newDishes.includes(dish._id)),
      );

      if (items.length) {
        onCategoriesChange(
          uniq([...categories, ...items.map((item) => item.category._id)]),
        );
      }
    },
    [categories, groupedData, onCategoriesChange],
  );

  const removeCategoryIfDishDeselected = useCallback(
    (newDishes: string[]) => {
      const items = groupedData.filter((item) =>
        item.dishes.some((dish) => !newDishes.includes(dish._id)),
      );

      if (items.length) {
        onCategoriesChange(
          categories.filter(
            (cat) => !items.map((item) => item.category._id).includes(cat),
          ),
        );
      }
    },
    [categories, groupedData, onCategoriesChange],
  );

  const handleCategoryChange = useCallback(
    (id: string, checked: boolean) => {
      if (checked) {
        onCategoriesChange(uniq([...categories, id]));
        const newDishes = uniq([
          ...dishes,
          ...(groupedData
            .find((item) => item.category._id === id)
            ?.dishes.map((dish) => dish._id) as string[]),
        ]);
        onDishesChange(newDishes);

        // Add category if all dishes are selected
        addCategoryIfAllDishesSelected(newDishes);
      } else {
        const newDishes = dishes.filter(
          (dish) =>
            !groupedData
              .find((item) => item.category._id === id)
              ?.dishes.map((dish) => dish._id)
              .includes(dish),
        );
        onCategoriesChange(categories.filter((category) => category !== id));
        onDishesChange(newDishes);

        // Remove category if one or more dishes are deselected from a category
        removeCategoryIfDishDeselected(newDishes);
      }
    },
    [
      addCategoryIfAllDishesSelected,
      categories,
      dishes,
      groupedData,
      onCategoriesChange,
      onDishesChange,
      removeCategoryIfDishDeselected,
    ],
  );

  const handleDishChange = useCallback(
    (dishId: string, checked: boolean) => {
      if (checked) {
        const newDishes = uniq([...dishes, dishId]);
        onDishesChange(newDishes);

        // Add category if all dishes are selected
        addCategoryIfAllDishesSelected(newDishes);
      } else {
        const newDishes = dishes.filter((dish) => dish !== dishId);
        onDishesChange(newDishes);

        // Remove category if one or more dishes are deselected
        removeCategoryIfDishDeselected(newDishes);
      }
    },
    [
      addCategoryIfAllDishesSelected,
      dishes,
      onDishesChange,
      removeCategoryIfDishDeselected,
    ],
  );

  const handleCollapsedCategory = (category: string) => {
    setCollapsedCategories((prev = []) => {
      const newSet = new Set(prev);
      if (newSet.has(category)) {
        newSet.delete(category);
      } else {
        newSet.add(category);
      }
      return Array.from(newSet);
    });
  };

  if (isLoading) {
    return (
      <Box>
        {Array.from({ length: 10 }).map((_, index) => (
          <Box
            key={index}
            sx={{
              borderBottom: `1px solid ${Colors.greyBorderLogin}`,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Skeleton height={40} width={400} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 1,
              }}>
              <Skeleton height={40} width={100} />
              <Skeleton height={40} width={25} />
            </Box>
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          border: error ? `1px solid ${Colors.error}` : 'none',
          borderRadius: '10px',
          padding: 1,
        }}>
        {/* TODO: Search option should go here. According to the search term, we need to filter the groupedData */}
        {groupedData.map((item) => (
          <Box key={item.category._id}>
            <Box
              sx={{
                borderBottom: `1px solid ${Colors.greyBorderLogin}`,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <FormControlLabel
                label={truncate(item.category.name, {
                  length: 40,
                  separator: ' ',
                })}
                control={
                  <Checkbox
                    checked={
                      categories.includes(item.category._id) &&
                      item.dishes.every((dish) => dishes.includes(dish._id))
                    }
                    value={item.category._id}
                    indeterminate={
                      !item.dishes.every((dish) => dishes.includes(dish._id)) &&
                      item.dishes.some((dish) => dishes.includes(dish._id))
                    }
                    onChange={(_e, checked) =>
                      handleCategoryChange(item.category._id, checked)
                    }
                  />
                }
              />

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 1,
                }}>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  sx={{ color: Colors.primary, whiteSpace: 'nowrap' }}>
                  {
                    item.dishes.filter((dish) => dishes.includes(dish._id))
                      .length
                  }{' '}
                  items selected
                </Typography>
                <IconButton
                  sx={{ color: Colors.black }}
                  onClick={() => handleCollapsedCategory(item.category._id)}>
                  <ExpandMore
                    sx={{
                      transition: 'transform 0.3s ease-in-out',
                      transform: collapsedCategories?.includes(
                        item.category._id,
                      )
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
            <Collapse in={collapsedCategories?.includes(item.category._id)}>
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                {item.dishes.map((dish) => (
                  <FormControlLabel
                    sx={{
                      borderBottom: `1px solid ${Colors.greyBorderLogin}`,
                      width: '100%',
                    }}
                    key={dish._id}
                    label={dish.name}
                    control={
                      <Checkbox
                        checked={dishes.includes(dish._id)}
                        onChange={(_e, checked) =>
                          handleDishChange(dish._id, checked)
                        }
                        value={dish._id}
                      />
                    }
                  />
                ))}
              </Box>
            </Collapse>
          </Box>
        ))}
      </Box>
      <Fade in={!!error}>
        {error ? (
          <Typography
            variant="caption"
            sx={{
              mt: 1,
              color: Colors.error,
              display: 'flex',
              lineHeight: 'normal',
              whiteSpace: 'nowrap',
            }}>
            {error}
          </Typography>
        ) : (
          <Box />
        )}
      </Fade>
    </>
  );
};

export default ComboCategoryDishSelector;
