import Box from '@mui/material/Box';

import { Colors } from '../../../theme/colors';

type Props = {
  children: React.ReactNode;
};

function ViewLayout({ children }: Readonly<Props>) {
  return (
    <Box
      sx={{
        flex: 3,
        borderRadius: 2,
        border: 1,
        borderColor: Colors.borderPrimary,
        p: 3,
        mb: 2,
        width: 650,
      }}>
      <Box> {children} </Box>
    </Box>
  );
}

export default ViewLayout;
