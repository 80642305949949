import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import AppCurrencyInput from '../../../components/AppCurrencyInput';
import AppSelector from '../../../components/AppSelector';
import AppTextInput from '../../../components/AppTextInput';
import { DISCOUNT_TYPES } from '../../../constants';
import { Colors } from '../../../theme/colors';
import { IDiscountDetailsForm } from '../constants/validation-schemas';

type Props = {
  disabled?: boolean;
};

const DiscountDetailsFormView = (props: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IDiscountDetailsForm>();
  const [showDiscountValue, setShowDiscountValue] = useState<boolean>(true);
  const [valueSuffix, setValueSuffix] = useState('%');

  const updateSuffix = useCallback((type: DISCOUNT_TYPES) => {
    if (type === DISCOUNT_TYPES.FIXED_VALUE) {
      setValueSuffix('$');
    } else if (type === DISCOUNT_TYPES.FIXED_PERCENTAGE) {
      setValueSuffix('%');
    } else {
      setValueSuffix('');
    }
  }, []);

  const discountTypeWatch = useWatch({
    control: control,
    name: 'discountType',
  });

  useEffect(() => {
    if (discountTypeWatch !== undefined) {
      updateSuffix(discountTypeWatch);
      setShowDiscountValue(
        [DISCOUNT_TYPES.FIXED_VALUE, DISCOUNT_TYPES.FIXED_PERCENTAGE].includes(
          discountTypeWatch,
        ),
      );
    }
  }, [discountTypeWatch, updateSuffix]);

  return (
    <>
      <Typography variant="h5" fontWeight={600} mb={3}>
        Discount Details
      </Typography>
      <Controller
        name="discountName"
        control={control}
        render={({ field: { onBlur, onChange, value } }) => (
          <AppTextInput
            label="Discount Name"
            placeholder="New Discount"
            type="text"
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            error={errors.discountName?.message}
            touched={true}
            data-testid="discountName"
          />
        )}
      />

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="discountType"
            render={({ field }) => (
              <AppSelector
                label="Discount Type"
                placeholder="Select Type"
                name="discountType"
                value={field.value}
                defaultValue={'Select Type'}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={errors.discountType?.message}
                sx={{ maxWidth: '200px' }}
                disabled={!!props.disabled}>
                <MenuItem value="Select Type">Select Type</MenuItem>
                <MenuItem value={DISCOUNT_TYPES.FIXED_PERCENTAGE}>
                  Fixed % Off
                </MenuItem>
                <MenuItem value={DISCOUNT_TYPES.FIXED_VALUE}>
                  Fixed $ Off
                </MenuItem>
                <MenuItem value={DISCOUNT_TYPES.OPEN_PERCENTAGE}>
                  Open % Off
                </MenuItem>
                <MenuItem value={DISCOUNT_TYPES.OPEN_VALUE}>
                  Open $ Off
                </MenuItem>
              </AppSelector>
            )}
          />
        </Grid>
        <Grid item xs={3}>
          {showDiscountValue && (
            <Controller
              name="discountValue"
              control={control}
              render={({
                field: { onBlur, onChange, value },
                fieldState: { error },
              }) => (
                <Box
                  sx={{
                    width: '100%',
                    position: 'relative',
                  }}>
                  <AppCurrencyInput
                    label="Value"
                    type="text"
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    touched={!!error}
                    suffix={valueSuffix}
                    data-testid="discountValue"
                    disabled={!!props.disabled}
                    sx={{
                      'input[type="number"]::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                      },
                      'input[type="number"]::-webkit-outer-spin-button': {
                        WebkitAppearance: 'none',
                      },
                      maxWidth: '110px',
                    }}
                  />
                  {/* Since the above text input is shorter in width, the error is displayed outside of the component */}
                  <Fade in={!!errors.discountValue}>
                    {errors.discountValue ? (
                      <Typography
                        variant="caption"
                        sx={{
                          bottom: '6px',
                          position: 'absolute',
                          color: Colors.error,
                          marginLeft: '8px',
                          display: 'flex',
                          lineHeight: 'normal',
                          whiteSpace: 'nowrap',
                        }}>
                        {errors.discountValue.message}
                      </Typography>
                    ) : (
                      <Box />
                    )}
                  </Fade>
                </Box>
              )}
            />
          )}
        </Grid>
      </Grid>

      <Controller
        name="discountDescription"
        control={control}
        render={({ field: { onBlur, onChange, value } }) => (
          <AppTextInput
            label="Discount Description"
            placeholder="New Discount Description"
            type="text"
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            touched={true}
            data-testid="discountDescription"
          />
        )}
      />
    </>
  );
};

export default DiscountDetailsFormView;
