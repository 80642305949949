import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

import { Colors } from '../theme/colors';

type AppDividerProps = {
  variant?: 'dashed' | 'solid' | 'dotted';
  style?: SxProps<Theme>;
};

const AppDivider = ({ variant = 'solid', style }: AppDividerProps) => {
  const defaultStyles = {
    container: {
      width: '100%',
      overflow: 'hidden',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    divider: {
      borderWidth: 2,
      borderColor: Colors.black,
      marginY: 1,
      width: '100%',
      borderTopStyle: variant,
    },
  };

  return (
    <Box sx={defaultStyles.container}>
      <Box sx={{ ...defaultStyles.divider, ...style }} />
    </Box>
  );
};

export default AppDivider;
