import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import AppButton from '../../../components/AppButton';
import { useConfirmationPopup } from '../../../components/AppConfirmationPopup';
import AppDivider from '../../../components/AppDivider';
import { SNACKBARTYPE, useSnackbar } from '../../../components/AppSnackbar';
import {
  DISCOUNT_APPLY_TYPES,
  DISCOUNT_STATUS,
  DISCOUNT_TYPES,
  PERMISSION_TYPES,
} from '../../../constants';
import HashScroll from '../../../hocs/HashScroll';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { selectDiscounts } from '../../../redux/selectors/discountsSelectors';
import { Colors } from '../../../theme/colors';
import { decimalCalculations } from '../../../utils';
import { DISCOUNT_STEP_ID } from '../constants';
import {
  discountValidationSchema,
  IDiscountForm,
} from '../constants/validation-schemas';
import ViewLayout from '../hocs/ViewLayout';
import useDiscountStepValidator from '../hooks/useDiscountStepValidator';
import {
  createDiscountsData,
  resetCreatingData,
} from '../redux/discountsSlice';
import { CreateDiscountRequestBodyDTO } from '../types/discounts.types';
import AdvancedFormView from '../views/AdvancedFormView';
import AppliedToFormView from '../views/AppliedToFormView';
import AvailabilityFormView from '../views/AvailabilityFormView';
import DiscountDetailsFormView from '../views/DiscountDetailsFormView';

const CreateDiscountsPage = () => {
  const dispatch = useAppDispatch();
  const { openConfirmationPopup } = useConfirmationPopup();
  const { creatingData } = useAppSelector(selectDiscounts);
  const { user } = useAppSelector(selectAuth);
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // Validate the previous step. If the previous step is not completed, redirect to the previous step
  useDiscountStepValidator(DISCOUNT_STEP_ID.ADVANCED);

  const form = useForm<IDiscountForm>({
    resolver: yupResolver(discountValidationSchema),
    defaultValues: {
      discountType: '' as DISCOUNT_TYPES,
      isAvailableAlways: true,
      permissionLevel: '' as PERMISSION_TYPES,
      discountReasons: [],
      combineDiscounts: true,
    },
  });

  useEffect(() => {
    if (creatingData) {
      form.setValue('discountName', creatingData.name ?? '');
      form.setValue(
        'discountType',
        creatingData.type ?? DISCOUNT_TYPES.FIXED_PERCENTAGE,
      );
      form.setValue(
        'discountValue',
        decimalCalculations(creatingData.value).toDecimal().toString(),
      );
      form.setValue('discountDescription', creatingData.description);
      const removeDiscount =
        (creatingData.excludedCategories?.length ?? 0) > 0 ||
        (creatingData.excludedDishes?.length ?? 0) > 0;

      form.setValue(
        'applyType',
        creatingData.applyType ?? DISCOUNT_APPLY_TYPES.ANY_ITEM,
      );
      form.setValue('removeDiscount', removeDiscount);

      form.setValue('excludedCategories', creatingData.excludedCategories);
      form.setValue('excludedDishes', creatingData.excludedDishes);

      form.setValue('includedCategories', creatingData.includedCategories);
      form.setValue('includedDishes', creatingData.includedDishes);

      form.setValue('isAvailableAlways', creatingData.isAvailableAlways);
      form.setValue(
        'permissionLevel',
        creatingData.permissionLevel ?? PERMISSION_TYPES.MANAGER,
      );
      form.setValue('discountReasons', creatingData.discountReasons);
      form.setValue('combineDiscounts', creatingData.combineDiscounts);
    }
  }, [creatingData, form]);

  const onSubmit = form.handleSubmit(async (data) => {
    const payload: CreateDiscountRequestBodyDTO = {
      name: data.discountName,
      createdBy: Number(user?.empId),
      value: decimalCalculations(data.discountValue).toCents().toNumber(),
      type: data.discountType,
      description: data.discountDescription,
      applyType: data.applyType,
      excludedDishes:
        data.applyType === DISCOUNT_APPLY_TYPES.FULL_ORDER &&
        data.removeDiscount
          ? data.excludedDishes
          : [],
      excludedCategories:
        data.applyType === DISCOUNT_APPLY_TYPES.FULL_ORDER &&
        data.removeDiscount
          ? data.excludedCategories
          : [],
      includedDishes:
        data.applyType === DISCOUNT_APPLY_TYPES.SPECIFIC_ITEM
          ? data.includedDishes
          : [],
      includedCategories:
        data.applyType === DISCOUNT_APPLY_TYPES.SPECIFIC_ITEM
          ? data.includedCategories
          : [],
      isAvailableAlways: data.isAvailableAlways,
      permissionLevel: data.permissionLevel,
      discountReasons: data.discountReasons,
      combineDiscounts: data.combineDiscounts,
      status: DISCOUNT_STATUS.ACTIVE,
    };

    const response = await dispatch(createDiscountsData(payload));
    if (response.meta.requestStatus === 'fulfilled') {
      openSnackbar('Discount created successfully!', SNACKBARTYPE.SUCCESS);
      navigate('/discounts');
    }
    dispatch(resetCreatingData());
  });

  const handlePreviousClick = () => {
    openConfirmationPopup({
      heading: `Discard Discount Changes?`,
      content: 'You will loose all the changes made to this discount.',
      confirmButtonTitle: 'Discard changes',
      cancelButtonTitle: 'Keep editing',

      onConfirm: () => {
        dispatch(resetCreatingData());
        navigate('/discounts/all-discounts');
      },
    });
  };

  return (
    <ViewLayout>
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <HashScroll hashInput="#discount-details">
            <DiscountDetailsFormView disabled={false} />
          </HashScroll>
          <AppDivider
            style={{
              borderColor: Colors.greyBorderLogin,
              borderTopStyle: 'solid',
              mt: 0.5,
              mb: 1.5,
            }}
          />
          <HashScroll hashInput="#applied-to">
            <AppliedToFormView disabled={false} />
          </HashScroll>
          <AppDivider
            style={{
              borderColor: Colors.greyBorderLogin,
              borderTopStyle: 'solid',
              mt: 0.5,
              mb: 1.5,
            }}
          />
          <HashScroll hashInput="#availability">
            <AvailabilityFormView />
          </HashScroll>
          <AppDivider
            style={{
              borderColor: Colors.greyBorderLogin,
              borderTopStyle: 'solid',
              mt: 0.5,
              mb: 1.5,
            }}
          />
          <HashScroll hashInput="#advanced">
            <AdvancedFormView />
          </HashScroll>
          <AppDivider
            style={{
              borderColor: Colors.greyBorderLogin,
              borderTopStyle: 'solid',
              mt: 0.5,
              mb: 1,
            }}
          />
          <Box
            sx={{
              display: 'flex',
              mt: 2,
              gap: 2,
              justifyContent: 'flex-end',
            }}>
            <AppButton
              size="large"
              title="Cancel"
              color="secondary"
              variant="contained"
              onClick={handlePreviousClick}
              disabled={form.formState.isSubmitting}
            />
            <AppButton
              size="large"
              title="Create"
              variant="contained"
              type="submit"
              disabled={form.formState.isSubmitting}
              loading={form.formState.isSubmitting}
            />
          </Box>
        </form>
      </FormProvider>
    </ViewLayout>
  );
};
export default CreateDiscountsPage;
