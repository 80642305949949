import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import correct from '../../../assets/svgs/correct.svg';
import AppRadioGroup from '../../../components/AppRadioGroup';
import { Colors } from '../../../theme/colors';

type Props = {
  defaultValue: string;
  value: string;
  label: string | React.ReactNode;
  disabled?: boolean;
  error?: string;
};

const CustomRadioButton = (props: Props) => {
  const radioStyles = useMemo(() => {
    if (props.value === props.defaultValue) {
      return {
        button: {
          backgroundColor: Colors.primaryLight,
          borderColor: Colors.primary,
        },
        text: {
          color: Colors.primary,
        },
      };
    } else if (props.error) {
      return {
        button: {
          borderColor: Colors.error,
        },
        text: {
          color: Colors.error,
        },
      };
    }
  }, [props.defaultValue, props.error, props.value]);

  return (
    <AppRadioGroup.CustomRadio
      value={props.defaultValue}
      label={
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 1,
            width: '190px',
            height: '72px',
            border: `1px solid ${Colors.greyBorderLogin}`,
            borderRadius: 1.2,
            overflow: 'hidden',
            ...radioStyles?.button,
          }}>
          {typeof props.label === 'string' ? (
            <Typography
              sx={{
                fontSize: '14px',
                textAlign: 'center',
                whiteSpace: 'pre-wrap',
                fontWeight: 500,
                color: radioStyles?.text.color,
              }}>
              {props.label}
            </Typography>
          ) : (
            props.label
          )}
          {props.value === props.defaultValue ? (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                padding: '0 4px',
                backgroundColor: Colors.primary,
                borderRadius: '0 0 8px 0',
              }}>
              <img src={correct} alt="icon" width={16} />
            </Box>
          ) : null}
        </Box>
      }
      disabled={props.disabled}
    />
  );
};

export default CustomRadioButton;
