import { array, boolean, InferType, object, string } from 'yup';

import {
  DISCOUNT_APPLY_TYPES,
  DISCOUNT_TYPES,
  PERMISSION_TYPES,
} from '../../../constants';
import { isFixedDiscountType } from '../utils/validation.utils';

export const discountDetailsValidationSchema = object({
  discountName: string()
    .max(30, 'Character limit exceeded (30)')
    .required('Enter discount name'),
  discountType: string()
    .oneOf(Object.values(DISCOUNT_TYPES), 'Select a Discount Type')
    .required('Select a Discount Type'),
  discountValue: string()
    .when('discountType', {
      is: (discountType: DISCOUNT_TYPES) => isFixedDiscountType(discountType),
      then: (schema) =>
        schema
          .required('Enter value')
          .transform((value) => value.replace(/,/g, ''))
          .test(
            'is-greater-than-zero',
            'Value must be greater than 0',
            (value) => Number(value) > 0,
          ),
      otherwise: (schema) => schema.notRequired(),
    })
    .when('discountType', {
      is: DISCOUNT_TYPES.FIXED_PERCENTAGE,
      then: (schema) =>
        schema.test(
          'is-less-than-100',
          'Value must be less than 100',
          (value) => Number(value) < 100,
        ),
    }),
  discountDescription: string(),
});

export const appliedToValidationSchema = object({
  applyType: string()
    .oneOf(Object.values(DISCOUNT_APPLY_TYPES), 'Select one option to proceed')
    .required('Select one option to proceed'),
  removeDiscount: boolean(),
  excludedCategories: array().of(string().required()),
  excludedDishes: array()
    .of(string().required())
    .when('removeDiscount', {
      is: true,
      then: (schema) =>
        schema.min(1, 'Select at least one category/item').required(),
    }),
  includedDishes: array()
    .of(string().required())
    .when('applyType', {
      is: DISCOUNT_APPLY_TYPES.SPECIFIC_ITEM,
      then: (schema) =>
        schema.min(1, 'Select at least one category/item').required(),
    }),
  includedCategories: array().of(string().required()),
});

export const advancedValidationSchema = object({
  combineDiscounts: boolean(),
  permissionLevel: string()
    .oneOf(Object.values(PERMISSION_TYPES))
    .required('Select a Permission Level.'),
  discountReasons: array(string().required()),
});

export const availabilityValidationSchema = object({
  isAvailableAlways: boolean(),
});

export const discountValidationSchema = object({
  discountName: string()
    .max(30, 'Character limit exceeded (30)')
    .required('Enter discount name'),
  discountType: string()
    .oneOf(Object.values(DISCOUNT_TYPES))
    .required('Select a Discount Type'),
  discountValue: string()
    .when('discountType', {
      is: (discountType: DISCOUNT_TYPES) => isFixedDiscountType(discountType),
      then: (schema) =>
        schema
          .required('Enter value')
          .transform((value) => value.replace(/,/g, ''))
          .test(
            'is-greater-than-zero',
            'Value must be greater than 0',
            (value) => Number(value) > 0,
          ),
      otherwise: (schema) => schema.notRequired(),
    })
    .when('discountType', {
      is: DISCOUNT_TYPES.FIXED_PERCENTAGE,
      then: (schema) =>
        schema.test(
          'is-less-than-100',
          'Value must be less than 100',
          (value) => Number(value) < 100,
        ),
    }),
  discountDescription: string(),

  applyType: string()
    .oneOf(Object.values(DISCOUNT_APPLY_TYPES), 'Select one option to proceed')
    .required('Select one option to proceed'),
  removeDiscount: boolean(),
  excludedCategories: array().of(string().required()),
  excludedDishes: array()
    .of(string().required())
    .when('removeDiscount', {
      is: true,
      then: (schema) =>
        schema.min(1, 'Select at least one category/item').required(),
    }),
  includedDishes: array()
    .of(string().required())
    .when('applyType', {
      is: DISCOUNT_APPLY_TYPES.SPECIFIC_ITEM,
      then: (schema) =>
        schema.min(1, 'Select at least one category/item').required(),
    }),
  includedCategories: array().of(string().required()),

  isAvailableAlways: boolean(),

  combineDiscounts: boolean(),
  permissionLevel: string()
    .oneOf(Object.values(PERMISSION_TYPES))
    .required('Select a Permission Level.'),
  discountReasons: array(string().required()),
});

export type IDiscountDetailsForm = InferType<
  typeof discountDetailsValidationSchema
>;
export type IAppliedToForm = InferType<typeof appliedToValidationSchema>;
export type IAvailabilityForm = InferType<typeof availabilityValidationSchema>;
export type IAdvancedForm = InferType<typeof advancedValidationSchema>;
export type IDiscountForm = InferType<typeof discountValidationSchema>;
