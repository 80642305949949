import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CREATE_DISCOUNT_STEPS, DISCOUNT_STEP_ID } from '../constants';
import useStepCompletedStatus from './useStepCompletedStatus';

const useDiscountStepValidator = (stepId: DISCOUNT_STEP_ID) => {
  const navigate = useNavigate();
  const currentStepIndex = CREATE_DISCOUNT_STEPS.findIndex(
    (step) => step.id === stepId,
  );
  const isCompleted = useStepCompletedStatus(
    CREATE_DISCOUNT_STEPS[currentStepIndex - 1]?.id,
  );

  useEffect(() => {
    // isCompleted will be undefined until the validation is done
    // isCompleted is false when the previous step is not completed or invalid
    if (isCompleted === false) {
      // Redirect to 1st step
      navigate(CREATE_DISCOUNT_STEPS[0].path);
    }
  }, [isCompleted, navigate]);

  return isCompleted;
};

export default useDiscountStepValidator;
